<template>
  <app-form-view
    app="document_template"
    model="businesscategory"
    api-url="doc-template/category/"
    :title="$t('menu.businessCategory')"
    :listRoute="{ name: 'businessCategory', query: $route.query }"
    :state="formData.state"
    :stateItems="stateItems"
    :approveItems="approveItems"
    :prepare-data="prepareData"
    v-model="formData"
  >
    <template v-slot="{ view }">
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="industryName"
            rules="required"
            type="select-server"
            :label="$t('fields.businessIndustry')"
            :view="view"
            :binds="{
              apiUrl: 'doc-template/industry/?active=true'
            }"
            v-model="formData.industry_id"
            ref="industry"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveIndustryName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_industry_id"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="name"
            rules="required"
            :label="$t('fields.name')"
            :view="view"
            v-model="formData.name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_name"
          />
        </v-col>
        <v-col cols="12" lg="6" class="app-input--approve-wrapper">
          <app-input
            name="secondaryName"
            rules="required"
            :label="$t('fields.secondaryName')"
            :view="view"
            v-model="formData.secondary_name"
          />
          <app-input
            v-if="isSentToApprove"
            name="approveSecondaryName"
            type="switch"
            class="approve"
            :label="$t('fields.approve')"
            v-model="formData.approve_secondary_name"
          />
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" lg="12">
          <app-input
            name="active"
            type="switch"
            :label="$t('fields.active')"
            :view="view"
            v-model="formData.active"
          />
        </v-col>
      </v-row>
    </template>
  </app-form-view>
</template>

<script>
import AppInput from '@components/AppInput'
import AppFormView from '@components/AppFormView'

export default {
  name: 'business-category-form',
  components: {
    AppInput,
    AppFormView
  },
  data() {
    return {
      formData: {
        state: 'draft'
      },
      approveItems: [
        'approve_name',
        'approve_secondary_name',
        'approve_industry_id'
      ]
    }
  },
  computed: {
    stateItems() {
      return [
        { label: this.$t('label.draft'), value: 'draft' },
        { label: this.$t('label.sentApprove'), value: 'sent_to_approve' },
        { label: this.$t('label.approve'), value: 'approved' }
      ]
    },
    isSentToApprove() {
      return this.formData.state === 'sent_to_approve'
    }
  },
  mounted() {
    const industry_id = this.$route.query.industry_id
    if (industry_id) {
      this.formData.industry_id = industry_id
      this.getData()
    }
  },
  methods: {
    prepareData(data) {
      if (data.industry_id?.id) {
        data.industry_id = data.industry_id.id
      }
      return data
    },
    getData() {
      {
        this.$api({
          method: 'get',
          url: 'doc-template/industry/?id=' + this.$route.query.industry_id,
          hideSuccessAlert: true
        }).then(({ data }) => {
          this.formData.industry_id = data.results[0]
          this.formData = Object.assign({}, this.formData)
          this.$refs.industry.setDefaultServerItems(this.formData.industry_id)
        })
      }
    }
  }
}
</script>
